.main-body {
  margin: 10px 0 26px 0;
  font-family: "Pretendard", sans-serif;
}

h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
}

.index-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.index-info .index-name {
  display: flex;
  gap: 11px;
}

.index-name > div {
  display: flex;
  align-items: center;
}

.index-name > div > p {
  margin: 0;
  padding-left: 4px;
  font-size: 14px;
}

.profit-color {
  width: 13px;
  height: 13px;
  background-color: #ff7676;
  border-radius: 100%;
}

.stability-color {
  width: 13px;
  height: 13px;
  background-color: #ffb876;
  border-radius: 100%;
}

.activity-color {
  width: 13px;
  height: 13px;
  background-color: #ffdd87;
  border-radius: 100%;
}

.productivity-color {
  width: 13px;
  height: 13px;
  background-color: #91d600;
  border-radius: 100%;
}

.mention-color {
  width: 13px;
  height: 13px;
  background-color: #87d4ff;
  border-radius: 100%;
}

.sentiment-color {
  width: 13px;
  height: 13px;
  background-color: #c376ff;
  border-radius: 100%;
}

.primary {
  background-color: #fbfbfb;
  color: #474747;
  border: 1px solid #474747;
  font-size: 15px;
  font-weight: bold;
}

.primary:hover {
  background-color: gainsboro;
  color: #474747;
  border: 1px solid gainsboro;
}

.primary > img {
  padding-right: 4px;
}

.custom-graph {
  display: flex;
  width: 100%;
  height: 28px;
  user-select: none;
  margin-bottom: 11px;
}

.custom-graph-section {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  font-size: 14px;
  text-align: center;
}

.handle {
  position: absolute;
  top: 0;
  right: -5px; /* handle를 센터에 위치 */
  width: 10px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #474747;
  border-radius: 10px;
  cursor: pointer;
  z-index: 10;
}

.sample-info {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 8px;
}

.sample-info > p {
  font-size: 13px;
  margin: 0;
}

.sample-info > img {
  width: 15px;
  height: 15px;
}

.index-sample {
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 20px;
}

.index-sample span {
  font-weight: bold;
}

.index-sample > label > input {
  margin-right: 10px;
  cursor: pointer;
}

[type="radio"]:checked {
  accent-color: #da0000;
}

.index-sample > label {
  font-size: 15px;
}

.sample-list {
  display: flex;
  justify-content: space-between;
}
