.nav-body {
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: "Pretendard", sans-serif;
}

#navbarScroll {
  background-color: white;
  z-index: 100;
}

.con-fluid {
  height: 6vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.me-auto .my-2 .my-lg-0 {
  max-height: 100px;
}

.searchForm .form-control::placeholder {
  font-size: 0.9em;
  /* 원하는 크기로 조정 */
  color: #6c757d;
  /* 선택 사항: placeholder 글자 색상 조정 */
}

.searchForm {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.includedSearchForm {
  position: absolute;
  top: 90%;
  z-index: 11;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  width: 19%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.included-result {
  text-decoration: none;
  padding: 5px;
  cursor: pointer;
  font-family: "SpoqaHanSansNeo-Regular";
  color: #414141;
}

.included-result:hover {
  background-color: #f1f1f1;
}

.nav-right-link {
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0;
  margin-left: 15px;
}

.nav-right-link p {
  letter-spacing: normal;
}

.nav-right-link p:hover {
  color: #000000;
  letter-spacing: 0.1cap;
  font-family: "SpoqaHanSansNeo-Bold";
}

.search-form {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  /* 회색 배경색 */
  border-radius: 20px;
  /* 모서리 radius */
  padding: 5px;
  margin-left: auto;
  /* 오른쪽 정렬을 위한 마진 설정 */
}

.search-form-group {
  display: flex;
  align-items: center;
}

.search-input {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  padding: 5px 0;
  flex: 1;
  font-family: "SpoqaHanSansNeo-Regular";
}

.search-input:focus {
  outline: none;
  box-shadow: none;
  background-color: transparent;
}