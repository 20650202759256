.cart-component {
  background-color: #f5f6fa;
  height: 100%;
  min-width: 860px;
}

@media (max-width: 1050px) {
  .cart-container {
    padding: 30px 50px;
  }
}

@media (min-width: 1050px) {
  .cart-container {
    padding: 30px 140px;
  }
}

.cart-container .cart-title {
  margin: 0px 0px 20px 19px;
  font-size: 24px;
  font-weight: bold;
}

.cart-container .cart-list {
  width: 100%;
  height: 995px;
  border-radius: 6px;
  background-color: white;
  padding: 28px;
}

.cart-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.cart-input {
  display: flex;
  gap: 12px;
}

#dropdown-basic {
  width: 180px;
  height: 40px;
  background-color: transparent;
  color: #a1a7c4;
  border: 1px solid #d9e1ec;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.di-search {
  width: 350px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid #d9e1ec;
  color: #a1a7c4;
  padding: 0px 0px 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-form-group {
  width: 100%;
}

.cart-buttons {
  display: flex;
  gap: 12px;
}

.backtest-button {
  cursor: pointer;
}

.trash-can {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

/* table */
.table-auto {
  width: 100%;
  text-align: center;
  align-items: center;
  border-collapse: collapse;
}

.table-container {
  max-height: 880px;
  overflow-y: auto;
}

.table-auto thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.price-change {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.table-auto thead {
  border-bottom: 1px solid #d9e1ec;
  padding-bottom: 5px;
}

.table-auto th {
  padding: 10px 0px;
  font-size: 14px;
  font-weight: bold;
  color: #5a607f;
}

.table-auto tr {
  border-bottom: 1px solid #d9e1ec;
  align-items: center;
}

.table-auto td {
  height: 100%;
  align-items: center;
  color: #5a607f;
}

.cart-sector {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}

.cart-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.ratio-setting {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 15px 0px;
}
